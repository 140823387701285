<template>
  <div class="container-fluid py-4 add-student grade-detail">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <div class="row">
                <div class="col-lg-6">
                  <h6 class="text-white text-capitalize ps-3">الحضور </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <div class="row align-center">
                    <div class="mb-3 col-lg-6">
                    <label>   اسم الدورة</label>
                    <multiselect
                        class='required form-control'
                        id='grade'
                        v-model="selectedCourse"
                        :options='courses'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder=" اختر الدورة"
                        @select='getCourseLectures($event)'
                        label='name'
                        track-by='name'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                   <div class="mb-3 col-lg-6">
                    <label>  المحاضرات</label>
                    <multiselect
                        class='required form-control'
                        id='lecture'
                        v-model="selectedLecture"
                        :options='activeLectures'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder=" اختر المحاضرة"
                        label='name'
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
            </div>
            <div class='row times course-info text-center'>
                <div class="col-md-3" >
                  <h5 class="info-h">الطلاب المسجلين في الدورة : <span class="green info-data">{{selectedLecture?selectedLecture.counts.enrolledStudents : null}}</span></h5>
                </div>
                <div class="col-md-3" >
                  <h5 class="info-h">الحضور : <span class="green info-data">{{selectedLecture?selectedLecture.counts.totalAttendStudents : null}}</span></h5>
                </div>
                 <div class="col-md-3" >
                  <h5 class="info-h">الغياب : <span class="green info-data">{{selectedLecture?selectedLecture.counts.totalAbsence : null}}</span></h5>
                </div>
                 <div class="col-md-3" >
                  <a data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                  <h5 class="info-h blue" >الإستثنائي : <span class="green info-data">{{selectedLecture?selectedLecture.counts.exceptionalStudents: null}}</span></h5>
                  </a>
                </div>
              </div>
                      <!-- exceptional attendance row -->
                        <div class="row align-center ex-row">
                <div class="mb-3 col-lg-3">
                  <label>  ادخل رقم الطالب أو الهاتف<span class="red">*</span></label>
                  <vmd-input
                    id="student-number"
                    type="text"
                   placeholder="  رقم الطالب او الهاتف "
                    v-model="exStudent.number"
                    @keyup.enter="search"
                    required
                  />
                </div>
                <div class="mb-3 col-lg-3">
                  <label> اسم الطالب </label>
                  <vmd-input
                    id="student-name"
                    type="text"
                    v-model="exStudent.full_name"
                    disabled
                  />
                </div>
                <div class="mb-3 col-lg-3">
                  <label>الفرقة </label>
                  <vmd-input
                    id="student-grade"
                    type="text"
                    v-model="exStudent.grade_name"
                    disabled
                  />
                </div>
                   <div class="mb-3 col-lg-3" v-if='normalAttendance'>
                <button @click='checkRegistration(exStudent.id, 1,$event)'
                 :disabled="buttonDisabled"
                style="margin-bottom:-2rem !important"
                  class="btn btn-success"
                        >
                           إيصال حضور
                        </button>
                        </div>
              </div>
            <div class="row align-center">
              <h5 class="exp-head" id="data" style="padding:15px"> الطلاب المتوقع حضورهم اليوم
                   <div> <vmd-checkbox
                      :checked="expList"
                      v-model="expList"
                      @change="getExpList"
                    ></vmd-checkbox>
                    <span style="font-size:14px">الحضور الاستثنائي </span>
                    </div>
                        </h5>
              <div class="text-center col-lg-12 overflow-x">
                <table class="table b-block attend-table">
                  <thead>
                    <tr>
                      <th> رقم </th>
                      <th> اسم الطالب</th>
                      <th>   الهاتف</th>
                      <th>الفرقة</th>
                       <th>مج</th>
                      <th> استثنائي</th>
                       <th> المسدد</th>
                       <th>المتبقي </th>
                      <th colspan="2"> حضور</th>
                       <th colspan="2"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr  v-for="student in expectedStudent" :key="student.id" :class="{'attend-back' :student.attend}">
                      <td>
                        {{ student.number }}
                      </td>
                      <td>
                        <span class="link pointer student-name" @click="showEnrolledCourses(student.id, student.name)">
                        {{ student.name }}
                        </span>
                      </td>
                      <td>
                        {{ student.mobile }}
                      </td>
                      <td>
                        {{ student.grade }}
                      </td>
                      <td>
                        {{ student.group }}
                      </td>
                       <td v-if='student.exeptional' class="text-center">
                     <span class="badge badge-sm bg-gradient-success "><i class="fas fa-check"></i></span>
                    </td>
                    <td v-else class="text-center">
                     <span class="badge badge-sm bg-gradient-danger ">X</span>
                    </td>
                    <td>
                        {{  $filters.number(student.payed)??'-' }}
                      </td>
                      <td>
                        {{  $filters.number(student.remain)??'-' }}
                      </td>
                      <td>
                        <button v-if="!student.attend"
                          class="btn btn-success width attend-btn"
                          @click="checkRegistration(student.id,1,$event)"
                           :disabled="buttonDisabled"
                        >
                           حضور
                        </button>
                        <button v-if='student.attend && !student.exeptional '
                          class="btn btn-success width attend-btn"
                          @click="checkRegistration(student.id,1,$event)"
                        >
                            طباعة
                        </button>
                        <button v-if='student.attend && student.exeptional '
                          class="btn btn-success width attend-btn"
                          @click="checkRegistration(student.id,'',$event,1)"
                        >
                            طباعة
                        </button>
                      </td>
                      <td>
                        <button
                          class="btn btn-warning width"
                          @click.prevent="paymentReceipt(student.id,0)"
                        >
                            دفع
                        </button>
                      </td>
                      <td  v-if="student.attend === true && removePermission">
                        <button id='cancel'
                          class="btn btn-danger width"
                          @click='cancelAttendance(student.id)'
                        >
                           الغاء
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <!-- start new student modal -->
      <div>
       <vue-final-modal
       :clickToClose="false" :close-on-esc="false"
      v-model="showModal3"
      name="example"
      classes="modal-container"
      content-class="modal-content"
    >
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">إضافة طالب جديد</h6>
              </div>
            </div>
           <NewStudent @hide-modal='showModal3= false' @new-attendance ="checkNewRegistration($store.state.newStudent,1,$event)"
           @update-table='getExpectedStudent'/>
          </div>
        </div>
      </div>
    </vue-final-modal>
    </div>
 <!-- start payment receipt modal -->
    <vue-final-modal
      :clickToClose="false" :close-on-esc="false"
    v-if="attend === 0"
      v-model="showModal"
      name="example"
      classes="modal-container"
      content-class="modal-content"
    >
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">إضافة إيصال</h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <form @submit.prevent="createReceipt" role="form" class="text-start mt-3">
                <div class="row">
                  <div class="mb-3 col-lg-6">
                    <label>
                      نوع الحركة
                      <span color="danger" class="red">*</span></label
                    >
                    <div class="input-group input-group-outline null">
                      <select class="form-control" v-model="selectedType">
                        <option value="d2d3aa71-8c7e-4f94-bbc9-ec516f1ba759">
                          نقدا
                        </option>
                        <option value="cec350eb-de0c-4fba-8e0d-110d236a95e8">
                          محفظة
                        </option>
                      </select>
                      <i class="fas fa-caret-down" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="mb-3 col-lg-6">
                    <label>
                      اسم الطالب
                      <span color="danger" class="red">*</span></label
                    >
                    <div class="input-group input-group-outline null">
                      <vmd-input
                        id="receipt-student"
                        type="text"
                        disabled
                        v-model="receiptInfo.name"
                      />
                    </div>
                  </div>
                  <div class="mb-3 col-lg-6">
                    <label>
                      اسم الدورة
                      <span color="danger" class="red">*</span>
                    </label>
                    <multiselect
                        class='required form-control'
                        id='grade'
                        v-model="selectedCourse"
                        :options='allCousres'
                        :searchable='true'
                        :close-on-select='true'
                        placeholder=" اختر الدورة"
                        label='name'
                        track-by='name'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                  </div>
                </div>
                <div class="row">
                  <div class="mb-3 col-lg-6">
                    <label>
                      القيمة <span color="danger" class="red">*</span></label
                    >
                    <vmd-input
                      id="receipt-amount"
                      type="number"
                      placehlder='القيمة'
                      v-model="receipt.amount"
                    />
                  </div>
                  <div class="mb-3 col-lg-6">
                    <label>
                      البيان <span color="danger" class="red">*</span>
                    </label>
                    <vmd-input
                      id="receipt-description"
                      type="text"
                      placeholder='سداد'
                      v-model="receipt.description"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="text-center col-lg-6">
                    <vmd-button
                      class="my-4 mb-2 pay-btn"
                      variant="gradient"
                      color="info"
                      @click="showModal = false"
                    >
                      حفظ</vmd-button
                    >
                  </div>
                  <div class="text-center col-lg-6">
                    <vmd-button
                      type="reset"
                      class="my-4 mb-2"
                      variant="gradient"
                      color="danger"
                      @click="showModal = false"
                    >
                      إلغاء
                    </vmd-button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </vue-final-modal>
    <!-- start student courses modal -->
    <vue-final-modal
      :clickToClose="false" :close-on-esc="false"
      v-model="showModal2"
      name="example"
      classes="modal-container"
      content-class="modal-content modal-2"
    >
    <studentCourses :enrolledCourses='enrolledCourses' :studentName='enrolledName'
   @hide-modal2='showModal2 = false' :studentId ='enrolledId'
 >
    </studentCourses>
    </vue-final-modal>
        <PrintReceipt :receipt="receipt"></PrintReceipt>
         <!-- exceptional table -->
      <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasExampleLabel"></h5>
          <button type="button" class="btn-close text-reset close-exp" data-bs-dismiss="offcanvas" aria-label="Close">x</button>
        </div>
        <div class="offcanvas-body">
          <div>
             <div class="row align-center" style=" margin-top: -36px;">
                <h5 id="data" style="padding:15px">الحضور الإستثنائي
                        </h5>
              <div class="text-center col-lg-12 overflow-x">
                <table class="table b-block">
                  <thead>
                    <tr>
                      <th> رقم </th>
                      <th>  الطالب</th>
                      <th>الفرقة</th>
                    </tr>
                  </thead>
                  <tbody v-if="selectedLecture">
                    <tr v-for="student in selectedLecture.counts.totalExceptionalData" :key="student.id">
                      <td>
                        {{ student.number }}
                      </td>
                      <td>
                        {{ student.full_name }}
                      </td>
                      <td>
                        {{ student.grade?student.grade.name:'-' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //new off canvas -->
  </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import NewStudent from '@/components/NewStudent.vue'
import StudentCourses from '@/components/StudentCourses.vue'
import VmdCheckbox from '@/components/VmdCheckbox.vue'
import swal from 'sweetalert'
import $ from 'jquery'
import { VueFinalModal } from 'vue-final-modal'
import VmdButton from '@/components/VmdButton.vue'
import PrintReceipt from '@/components/PrintReceipt.vue'
const date = new Date()
const today =
  date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
let timeout = null
export default {
  name: 'StudentAttendance',
  components: {
    VmdInput,
    VmdButton,
    VueFinalModal,
    VmdCheckbox,
    PrintReceipt,
    NewStudent,
    StudentCourses
  },
  data () {
    return {
      student: {},
      receiptInfo: {},
      showExRow: false,
      buttonDisabled: false,
      expList: false,
      courses: [],
      allCousres: [],
      expectedStudent: [],
      // exceptional student
      exStudent: {},
      exception: 0,
      selectedCourse: '',
      activeLectures: [],
      searchLecture: '',
      selectedLecture: {
        counts: {}
      },
      attend: 0,
      course: {},
      normalAttendance: false,
      receiptTypes: [],
      receipt: {
        full_name: null,
        student_number: null,
        amount: '',
        title: 'حضور',
        description: '',
        userName: localStorage.userName,
        branchName: localStorage.branchName
      },
      searchMobile: '',
      selectedType: 'd2d3aa71-8c7e-4f94-bbc9-ec516f1ba759',
      receiptTitle: '',
      showModal: false,
      showModal2: false,
      showModal3: false,
      enrolledCourses: [],
      enrolledName: '',
      enrolledId: '',
      removePermission: false,
      courseINfo: {},
      errors: []
    }
  },
  created () {
    this.removePermission = localStorage.permissions.includes('Attendance:Remove')
    const courses = []
    this.$http.get(`${this.$hostUrl}expected-students?course_id=&lecture_id=`).then((response) => {
      $.each(response.data.data.courses, function (key, val) {
        courses.push({
          name: val.number + '-' + val.name,
          id: val.id
        })
      })
      this.courses = courses
    })
    const allCourses = []
    this.$http.get(`${this.$hostUrl}courses`).then((response) => {
      $.each(response.data.data, function (key, val) {
        allCourses.push({
          name: val.name,
          id: val.id
        })
      })
      this.allCousres = allCourses
    })

    this.receiptTypes = [
      {
        name: 'نقدا',
        id: 'd2d3aa71-8c7e-4f94-bbc9-ec516f1ba759'
      },
      {
        name: 'محفظة',
        id: 'cec350eb-de0c-4fba-8e0d-110d236a95e8'
      }
    ]
  },
  computed: {
    updatedInfo () {
      return this.courseINfo
    }

  },
  methods: {
    getCourseLectures ($event) {
      const activeLectures = []
      const current = this
      this.$http
        .get(`${this.$hostUrl}active-lectures?date=${today}&&course_id=${$event.id}`)
        .then((response) => {
          $.each(response.data.data, function (key, val) {
            activeLectures.push({
              name: 'محاضرة ' + val.lecture_number + ' ' + '||' + ' ' + 'التوقيت' + ' ' + ' ' + current.$filters.formatTime((val.start).slice(-5)),
              courseName: current.selectedCourse.name,
              course: {
                id: current.selectedCourse.id,
                name: current.selectedCourse.name
              },
              grade: val.grade,
              counts: {
                enrolledStudents: val.enrolled_students,
                exceptionalStudents: val.exceptional_students.length,
                totalAbsence: val.total_absence,
                totalAttendStudents: val.attend_students,
                totalExceptionalData: val.exceptional_students
              },
              id: val.id
            })
          })
          console.log(this.activeLectures.length)
          this.activeLectures = activeLectures
          if (this.activeLectures.length === 1) {
            this.selectedLecture = {
              id: this.activeLectures[0].id,
              name: this.activeLectures[0].name,
              counts: this.activeLectures[0].counts
            }
          }
        })
    },
    getExpectedStudent ($event) {
      const expectedStudent = []
      if (!this.exStudent) {
        swal('الرجاء ادخال رقم الطالب او رقم الهاتف')
      }
      const studentId = this.exStudent.id
      this.$http.get(`${this.$hostUrl}expected-students?course_id=${this.selectedCourse.id}&lecture_id=${$event ? $event.id : this.selectedLecture.id}&student_id=${studentId}`)
        .then((response) => {
          $.each(response.data.data.students, function (key, val) {
            expectedStudent.push({
              id: val.id,
              number: val.number,
              name: val.full_name,
              mobile: val.mobile,
              course: val.course,
              lecture: val.lecture,
              grade: val.grade ? val.grade.name : '-',
              group: val.group ? val.group.name : '-',
              payed: val.payed,
              remain: val.remain,
              attend: val.attend,
              exeptional: val.exeptional

            })
          })
          this.expectedStudent = expectedStudent
        })
    },
    updateTableData () {
      const expectedStudent = []
      if (!this.exStudent) {
        swal('الرجاء ادخال رقم الطالب او رقم الهاتف')
      }
      const studentId = this.exStudent.id
      this.$http.get(`${this.$hostUrl}expected-students?course_id=${this.selectedCourse.id}&lecture_id=${this.selectedLecture.id}&student_id=${studentId}`)
        .then((response) => {
          $.each(response.data.data.students, function (key, val) {
            expectedStudent.push({
              id: val.id,
              number: val.number,
              name: val.full_name,
              mobile: val.mobile,
              course: val.course,
              lecture: val.lecture,
              grade: val.grade ? val.grade.name : '-',
              group: val.group ? val.group.name : '-',
              payed: val.payed,
              remain: val.remain,
              attend: val.attend,
              exeptional: val.exeptional
            })
          })
          this.expectedStudent = expectedStudent
          this.getCourseInfo()
        })
    },
    getCourseInfo () {
      const current = this
      this.$http
        .get(`${this.$hostUrl}active-lectures?date=${today}&&course_id=${this.selectedCourse.id}`)
        .then((response) => {
          $.each(response.data.data, function (key, val) {
            if (val.id === current.selectedLecture.id) {
              current.selectedLecture.counts = {
                enrolledStudents: val.enrolled_students,
                exceptionalStudents: val.exceptional_students.length,
                totalAbsence: val.total_absence,
                totalAttendStudents: val.attend_students,
                totalExceptionalData: val.exceptional_students
              }
            }
          })
        })
    },
    openModal () {
      this.showModal = true
    },
    getExpList () {
      if (this.expList) {
        this.expectedStudent = this.expectedStudent.filter((val) => {
          console.log(val.exeptional === 1, 'kkk')
          return val.exeptional === true
        })
      } else {
        this.getExpectedStudent()
      }
    },
    showEnrolledCourses (id, name) {
      this.showModal2 = true
      this.enrolledCourses = []
      this.enrolledName = ''
      this.$http.get(`${this.$hostUrl}student/courses?student_id=${id}`).then((response) => {
        this.enrolledCourses = response.data.data
        this.enrolledName = name
        this.enrolledId = id
      })
    },
    search () {
      if (!this.selectedLecture.id) {
        swal(' يجب اختيار المحاضرة')
        this.exStudent.number = ''
      } else {
        this.exStudent.id = null
        this.exStudent.full_name = null
        this.exStudent.grade_name = null
        const current = this
        this.$store.commit('setSearchVal', current.exStudent.number)
        clearTimeout(timeout)
        timeout = setTimeout(function () {
          current.$http
            .get(`${current.$hostUrl}students-list?search=${current.exStudent.number}`)
            .then((response) => {
              if (response.data.data.length === 1) {
                current.exStudent.full_name = response.data.data[0].full_name
                current.exStudent.id = response.data.data[0].id
                current.exStudent.number = response.data.data[0].number
                current.exStudent.grade_name = response.data.data[0].grade_name
                current.$http
                  .get(`${current.$hostUrl}enrolled/${current.selectedLecture.id}/${current.exStudent.id}`)
                  .then((response) => {
                    if (!response.data.data.enrolled) {
                      current.normalAttendance = false
                      current.exptionalAttendance(current.exStudent.id, current.exStudent.full_name, current.exStudent.number)
                    } else {
                      current.normalAttendance = true
                    }
                  })
                current.getExpectedStudent()
              } else {
                current.exStudent.id = null
                current.exStudent.full_name = null
                current.exStudent.grade_name = null
                swal({
                  title: ' الطالب غير موجود ',
                  icon: 'warning',
                  buttons: ['لا', 'إضافة '],
                  dangerMode: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33'
                }).then((willDelete) => {
                  if (willDelete) {
                    current.showModal3 = true
                  } else {
                    swal({ title: ' تم الإلغاء  !', buttons: 'تم' })
                  }
                })
              }
              if (response.data.error) {
                swal(response.data.message)
              }
            })
        }, 800)
      }
    },
    async checkRegistration (id, attend, e, exception) {
      this.buttonDisabled = true
      const current = this
      this.exception = exception
      this.attend = attend
      // get selected student data
      const selectedStudent = current.expectedStudent.filter((val) => {
        return val.id === id
      })
      current.receiptInfo = selectedStudent[0]
      current.receiptInfo.course = current.selectedCourse.name
      if (this.attend) {
        if (!this.exception || this.exception === 'undefined') {
          this.receipt.description = 'سداد'
          this.createReceipt(e)
        }
      } if (this.exception) {
        this.receipt.description = 'حضور استثنائي'
        this.createReceipt(e)
      }
      setTimeout(() => {
        this.buttonDisabled = false
      }, 3000)
    },
    async checkNewRegistration (student, attend, e, exception, studentNumber) {
      this.exception = exception
      this.attend = attend
      const current = this
      console.log('this.student', student)
      this.exStudent = student
      console.log('this.exStudent', this.exStudent)
      current.$http
        .get(`${current.$hostUrl}students-list?id=${student.id}`)
        .then((response) => {
          current.receiptInfo = response.data.data
          current.receiptInfo.course = current.selectedCourse.name
          if (this.attend) {
            if (!this.exception || this.exception === 'undefined') {
              this.receipt.description = 'سداد'
              this.createReceipt(e)
            }
          } if (this.exception) {
            this.receipt.description = 'حضور استثنائي'
            this.receipt.amount = ''
            this.receipt.attend = ''
            this.receipt.enroll = ''
            this.createReceipt(e)
          }
          this.getExpectedStudent()
        })
    },
    async paymentReceipt (id, attend) {
      this.attend = attend
      this.exception = 0
      // get selected student data
      const selectedStudent = this.expectedStudent.filter((val) => {
        return val.id === id
      })
      this.receiptInfo = selectedStudent[0]
      this.receiptInfo.course = this.selectedCourse.name
      this.receipt.description = 'سداد'
      this.receipt.amount = ''
      const current = this
      current.showModal = true
    },
    async createReceipt (e) {
      this.receipt.created_at = ''
      const current = this
      current.receipt.attend = null
      current.receipt.enroll = null
      // e.preventDefault()
      const formData = new FormData()
      this.receipt.course = this.selectedCourse.name
      this.receipt.full_name = this.receiptInfo.full_name
      this.receipt.student_number = this.receiptInfo.number
      formData.append('student_id', this.receiptInfo.id)
      formData.append('receipt_type_id', this.selectedType)

      formData.append(
        'transaction_type_id',
        '1413a440-d013-416c-a882-dc23fb8d52c4'
      )
      formData.append('amount', this.receipt.amount)
      if (this.receipt.description) {
        formData.append('description', this.receipt.description)
      }
      if (this.attend) {
        formData.append('attend', 1)
        formData.append('enroll', 1)
        formData.append('payable_id', this.selectedLecture.id)
      } else if (this.exception) {
        formData.append('exceptional', 1)
        formData.append('attend', '')
        formData.append('enroll', '')
        formData.append('payable_id', this.selectedLecture.id)
      } else if (!this.attend && !this.exception) {
        formData.append('attend', 0)
        formData.append('payable_id', this.selectedCourse.id)
      }
      $('.pay-btn').attr('disabled', true)
      await this.$http.post(this.$hostUrl + 'receipts', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          $('.pay-btn').attr('disabled', false)
          if (response.data.error) {
            swal({ title: response.data.message, buttons: 'تم' })
          } else {
            current.receipt = response.data.data
            current.receipt.transaction_type = response.data.data.transaction_type_name
            current.receipt.student_number = this.receiptInfo.number
          }
        })
        .catch(function (error) {
          $('.pay-btn').attr('disabled', false)
          if (Object.prototype.hasOwnProperty.call(error, 'response')) {
            if (error.response.data.data) {
              current.errors = error?.response?.data?.data
              swal(current.errors?.student_id || current.errors?.course_id)
            } else {
              swal({ title: error.response.data.message, buttons: 'تم' })
            }
            window.scrollTo(0, 0)
          }
        })
      if (current.receipt?.created_at) {
        setTimeout(
          current.$htmlToPaper(
            'print-receipt'
          ),
          10000
        )
        this.showModal3 = false
      }
      // refresh data
      this.updateTableData()
    },
    async cancelAttendance (id) {
      swal({
        title: 'هل انت متأكد ؟',
        text: 'بمجرد الموافقة ، سيتم الغاء حضور الطالب  !',
        icon: 'warning',
        buttons: ['لا', 'نعم'],
        dangerMode: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((willDelete) => {
        if (willDelete) {
          this.$http
            .delete(
              `${this.$hostUrl}attendance/remove/${this.selectedLecture.id}/${id}`,
              { _method: 'delete' }
            )
            .then((response) => {
              swal({ title: response.data.message, buttons: 'تم' })
            })
          // refresh data
          this.updateTableData()
        } else {
          swal({ title: ' تم الإلغاء  !', buttons: 'تم' })
        }
      })
    },
    async exptionalAttendance (id, name, number) {
      const current = this
      swal({
        title: `الطالب : ${name} `,
        text: 'غير مسجل بالدورة',
        buttons: {
          exceptionalAttentBtn: {
            text: 'حضور استثنائي',
            visible: true
          },
          registerBtn: {
            text: 'تسجيل في الدورة',
            visible: true
          },
          cancel: 'إلغاء'
        },
        dangerMode: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: 'red'
      }).then((value) => {
        const formData = new FormData()
        switch (value) {
          case 'exceptionalAttentBtn':
            console.log('this.exStudent', this.exStudent)
            current.receiptInfo.id = id
            current.receiptInfo.name = name
            current.receiptInfo.number = number
            current.receiptInfo.course = current.selectedCourse.name
            current.exception = 1
            current.attend = 0
            current.receipt.description = 'حضور استثنائي'
            formData.append('enroll', '')
            formData.append('attend', '')
            current.createReceipt()
            // refresh data
            this.getExpectedStudent()
            break
          case 'registerBtn' :
            formData.append('student_id', id)
            formData.append('course_id', this.selectedCourse.id)
            this.errors = this.postRequest(
              formData,
              this.$hostUrl + 'course/enroll',
              ''
            ).then((response) => {
              console.log(response, 'comp')
              if (response.course_id) { swal(response.course_id[0]) } else if (response.student_id) { swal(response.student_id[0]) }
              // refresh data
              this.getExpectedStudent()
            })

            break
          default :
            swal({ title: ' تم الإلغاء  !', buttons: 'تم' })
            this.exStudent = {}
        }
      })
    }
  }
}
</script>
<style>
.swal-button{
  background-color: #2bb1f1 !important;
}
.swal-button--cancel{
  background: #e94646 !important;
  color:#fff
}
#data {
  background: #55ae59;
  color: #fff;
  padding: 13px;
  border-radius: 8px;
  margin: 30px 0px;
}
.modal-2{
       width: 75% !important;
    margin-right: 232px;
}
.showAttend {
  display: none !important;
}
.block {
  display: block !important;
}
/* .attend {
  margin: 0 !important;
} */
</style>
<style scoped>
.search {
  background-color: #fff;
  display: inline;
  width: auto;
  float: left;
  padding: 7px;
}
.search:focus {
  background-color: #fff;
}
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal__title {
  font-size: 1.5rem;
  font-weight: 700;
}
::v-deep .modal-content {
  width: 50% ;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
.exceptional{
      width: 14% !important;
    float: left !important;
    background: #fff;
    color:#222;
    margin:0 !important;
}
.width{
  width:100%;
}
.online{
  border:1px solid #ddd
}
.course-info{
      background: #f8f9fa;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 38px;
    font-size: 14px;
}
.info-h{
      font-size: 14px !important;
    font-weight: 500;
}
.courses-modal{
    max-height: 400px;
    overflow-y: auto;
}
.info-data{
      font-family: "Roboto", Helvetica, Arial, sans-serif !important;
      font-weight:bold !important;
      font-size:17px !important
}
.blue{
  color:blue;
}
.mr-auto{
  margin: auto !important;
}
.close-exp{
     font-family: "Roboto", Helvetica, Arial, sans-serif !important;
     font-weight: bold !important;
    color: #222 !important;
    background: #f1f1f1;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    float: right;
    position:absolute;
    top:10px !important;
}
.attend-table > tbody > tr {
  background: #fb060669;
}
.attend-back{
 background: #5fc1727a !important;
}
.exp-head{
  display: flex;
  justify-content: space-around;
}
.exp-head div{
  display: flex;
  align-items: center;
}
.student-name{
  display: flex;
    text-overflow: ellipsis;
    width: 102px;
    display: flex;
    flex-wrap: wrap;
    white-space: pre-wrap;
}
.course-name{
  display: flex !important;
    text-overflow: ellipsis;
    width: 140px !important;
    display: flex;
    flex-wrap: wrap;
    white-space: pre-wrap;
}
.offcanvas.show{
  visibility: visible !important;
}
</style>
